import React, { useState, useEffect, useCallback, memo } from 'react';
import loadable from '@loadable/component';
import { createBrowserHistory } from 'history';
import { Router, Route, Switch } from 'react-router-dom';
import { AppInsightsContext } from '@microsoft/applicationinsights-react-js';

import './App.css';

import axios, { setUpAxiosClientIdHeader } from 'axios.config';
import Logger from 'common/utils/logger';
import { CallbackPage } from 'common/pages/CallbackPage';
import { RedirectPage } from 'common/pages/RedirectPage';
import { UserDataContext } from 'common/context/userContext';
import { BrandDataContext } from 'common/context/brandContext';
import { ErrorBoundary } from 'common/ErrorBoundary';
import { localCache } from 'localCache';
import { LoadingOverlay } from 'common/components/LoadingOverlay/LoadingOverlay';
import { reactPlugin } from './AppInsights';

const BrandPage = loadable(
  (props: { name: string }) => import(/* webpackChunkName: "[request]" */ `./brands/${props.name}`)
);

let is_idp_flow: boolean | undefined = false;

const getBrand = async (clientId: string) => {
  return axios.get(`/lookup_func`, { headers: { client_id: clientId, idp_flow: is_idp_flow || false } });
};

export const history = createBrowserHistory();

const App = memo((props: any) => {
  const [userData, setUserData] = useState<any>();
  const [brandData, setBrandData] = useState<any>();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Error>();

  const updateUserData = useCallback((profileData: any) => {
    if (profileData) {
      const { firstName, lastName, c_origin } = profileData;
      setUserData({ firstName, lastName, c_origin });
    }
  }, []);

  const userDataContext = React.useMemo(
    () => ({
      userData,
      updateUserData,
    }),
    [userData, updateUserData]
  );

  // Expose loading state globally
  // @ts-ignore
  window.setLoading = setLoading;

  let currentURL = new URL(window.location.href);
  let flow = window.location.pathname;
  let isIdP = new URLSearchParams(window.location.search).get('is_idp') || '';
  is_idp_flow = flow?.includes('idp-signin') || isIdP === 'true';
  let clientId = new URLSearchParams(window.location.search).get('client_id') || '';
  let email = new URLSearchParams(window.location.search).get('email') || '';

  if (clientId) {
    sessionStorage.setItem('current-client-id', clientId);
  } else {
    clientId = window.location.origin;
  }

  if (email) {
    sessionStorage.setItem('email', email);
  }

  useEffect(() => {
    const brandData = localCache.brand;

    if (brandData && (brandData.value.client_id.includes(clientId) || brandData.value.hostname === clientId)) {
      setBrandData(brandData.value);
      return;
    } else {
      delete localCache.brand;
    }

    fetchBrandData(clientId);
  }, [clientId]);

  const fetchBrandData = async (clientId: string) => {
    try {
      const response = await getBrand(clientId);
      console.log('brand_details', response);
      const UI_DATA_CACHING_ALLOWED = response.data.result.brand_metadata?.flags?.UI_DATA_CACHING_ALLOWED;
      if (UI_DATA_CACHING_ALLOWED) {
        const expireIn = response.data.result.brand_metadata?.configs?.CACHING_TIME_IN_MINUTES;
        localCache.brand = { ...response.data.result, expireIn };
        console.debug(`Data cached for ${expireIn} minutes`);
      }
      setBrandData(response.data.result);
    } catch (error: any) {
      console.debug('Error Occurred:', error.response?.data.response_code);
      let url = new URL(currentURL.href);
      url.searchParams.delete('client_id');
      window.location.replace(url.href);
      setError(error);
    }
  };

  useEffect(() => {
    if (brandData) {
      let fallbackClientId = new URLSearchParams(window.location.search).get('client_id') || '';

      if (!fallbackClientId) {
        fallbackClientId = brandData.okta_domain_details.chameleon_client_id;
      }

      setUpAxiosClientIdHeader(fallbackClientId);
      loadI8nFile(brandData);

      Logger.setMetaData(brandData.brand_name, 'placeholder');
      Logger.setLogLevels(brandData.brand_metadata.log_levels || ['all']);
    }
  }, [brandData]);

  const handleReload = useCallback(() => {
    window.location.reload();
  }, []);

  const loadI8nFile = useCallback(async (brandData: any) => {
    try {
      const setupI18n = (await import(`./brands/${brandData.brand_name}/locales/i18n`)).default;
      const overrides = brandData.brand_metadata.locale_override;
      setupI18n(overrides);
    } catch (error: any) {
      setError(error);
    }
  }, []);

  if (error) {
    return (
      <div className="App">
        <div className="App-header" style={{ color: 'black' }}>
          Something went wrong.
        </div>
      </div>
    );
  }

  if (loading || !brandData) {
    return (
      <LoadingOverlay active={loading || !brandData} text="Loading...">
        <div className="App App-header" style={{ color: 'black' }}></div>
      </LoadingOverlay>
    );
  }

  return (
    <AppInsightsContext.Provider value={reactPlugin}>
      <BrandDataContext.Provider value={brandData}>
        <UserDataContext.Provider value={userDataContext}>
          <Router history={history}>
            <ErrorBoundary>
              <Switch>
                <Route exact path="/callback" component={CallbackPage} />
                <Route exact path="/redirect/:clientId" component={RedirectPage} />
                <BrandPage name={brandData.brand_name} />
              </Switch>
            </ErrorBoundary>
          </Router>
        </UserDataContext.Provider>
      </BrandDataContext.Provider>
    </AppInsightsContext.Provider>
  );
});

export default App;
