import { lazy, Suspense, FC } from 'react';
import { LoadingOverlayProps } from 'react-loading-overlay';

const LazyLoadingOverlay = lazy(() => import('react-loading-overlay').then(mod => ({ default: mod.default })));

const overlayStyles: LoadingOverlayProps['styles'] = {
  overlay: (base: React.CSSProperties) => ({
    ...base,
    background: 'rgba(255, 99, 71, 0)',
  }),
  spinner: (base: React.CSSProperties) => ({
    ...base,
    '& svg circle': {
      stroke: '#002f52',
      strokeWidth: 3,
    },
  }),
  content: (base: React.CSSProperties) => ({
    ...base,
    fontSize: '20px',
    fontFamily: 'Arial',
    color: '#002f52',
  }),
};

interface Props extends LoadingOverlayProps {
  active: boolean;
}

export const LoadingOverlay: FC<Props> = ({ active, ...props }) => {
  if (!active) return null;

  return (
    <Suspense fallback={<div>Loading...</div>}>
      <LazyLoadingOverlay active={active} spinner styles={overlayStyles} {...props} />
    </Suspense>
  );
};
